import { useForm } from "@mittwald/flow-components/dist/components/Form";
import { useAutoSave } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useAutoSave";
import { InlineError } from "@mittwald/flow-components/dist/components/InlineError";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextArea } from "@mittwald/flow-components/dist/components/TextArea";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { UniversalBoundary } from "@mittwald/flow-components/dist/components/UniversalBoundary";
import { WizardIntroStep } from "@mittwald/flow-components/dist/components/Wizard/components";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import useWizardState from "@mittwald/flow-components/dist/components/Wizard/hooks/useWizardState";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useTranslation } from "@mittwald/flow-components/dist/hooks/useTranslation";
import {
  useVisibilityController,
  VisibilityController,
} from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconSupport } from "@mittwald/flow-icons/dist/support";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import invariant from "invariant";
import React, { FC, Suspense, useEffect, useState } from "react";
import InputFieldSkeleton from "../../../../../components/InputFieldSkeleton/InputFieldSkeleton";
import ModelRelationType from "../../../../../model/misc/modelRelation/ModelRelationType";
import Conversation, {
  ConversationCreateInput,
} from "../../../../../model/support/Conversation";
import { ConversationCategoryList } from "../../../../../model/support/ConversationCategoryList";
import { ConversationCategoryListUI } from "../../../../../model/ui/support/ConversationCategoryListUI";
import { ConversationRelationUI } from "../../../../../model/ui/support/ConversationRelationUI";
import { getDefaultRelatedToChild } from "../../misc/getDefaultRelatedToChild";
import { AppInstallationSelect } from "./components/AppInstallationSelect";
import { ConversationRelationSelect } from "../../components/ConversationRelationSelect";
import { CreateConversationAvatarStack } from "./components/CreateConversationAvatarStack";
import { FileDropzoneComponent } from "./components/FileDropzoneComponent";
import { SelectContainer } from "./styled";
import Select from "@mittwald/flow-components/dist/components/Select";

interface CreateConversationProps {
  visibilityController?: VisibilityController;
  relatedTo?: {
    id?: string;
    aggregate: string;
    domain: string;
  };
  relatedToChild?: {
    id?: string;
    aggregate: string;
    domain: string;
  };
  categoryName?: string;
  autoSaveKey?: string;
  redirectAfterCreate?: boolean;
  onConversationCreated?: (conversation: Conversation) => void;
}

export interface CreateConversationFormType extends ConversationCreateInput {
  relationTypeName: string;
  message: string;
  conversation: Conversation | undefined;
  fileIds: string[];
  relatedToChild: {
    id: string;
    aggregate: string;
    domain: string;
  };
}

export const CreateConversation: FC<CreateConversationProps> = (props) => {
  const {
    visibilityController,
    relatedTo,
    relatedToChild,
    categoryName,
    autoSaveKey,
    redirectAfterCreate,
    onConversationCreated,
  } = props;
  const goto = useGotoLink();
  const visibility = visibilityController ?? useVisibilityController();
  const categories = ConversationCategoryList.useList();
  const categoryListUI = ConversationCategoryListUI.of(categories);
  const relationsUI = new ConversationRelationUI();
  const translate = useTranslation();
  const wizardState = useWizardState();

  const relationOptions = relationsUI.getRelationSelectOptions();

  const defaultRelationTypeName = relationOptions.find(
    (c) => !c.disabled,
  )!.value;
  const defaultRelatedTo = relationsUI.relationTypeByName(
    defaultRelationTypeName,
  );
  const defaultRelatedToChild = getDefaultRelatedToChild();

  const form = useForm<CreateConversationFormType>({
    translationKey: "newConversation",
    defaultValues: {
      title: "",
      message: "",
      relationTypeName: defaultRelationTypeName,
      categoryId: "",
      relatedTo: {
        id: "",
        domain: defaultRelatedTo.domain,
        aggregate: defaultRelatedTo.aggregate,
      },
      relatedToChild: {
        id: "",
        domain: defaultRelatedToChild.domain,
        aggregate: defaultRelatedToChild.aggregate,
      },
    },
    resetFormOnSuccessWithNewValues: false,
    onSubmit: async (values) => {
      invariant(values.conversation, "conversation must exist");
      invariant(values.title, "title must be set");

      const fetchedConversation = await Conversation.loadById(
        values.conversation.id,
      );

      await fetchedConversation.updateTitle(values.title);

      await fetchedConversation.addMessage({
        content: values.message,
        fileIds: values.fileIds,
      });

      form.reset();
      wizardState.gotoStep(0);
      if (redirectAfterCreate !== false) {
        goto("conversationDetails", {
          conversationId: fetchedConversation.id,
        });
      }
      onConversationCreated?.(fetchedConversation);
    },
  });

  useAutoSave(form, autoSaveKey || "createConversation", {
    ignoredKeys: ["relatedTo", "relatedToChild", "fileIds"],
  });

  const [
    watchedConversation,
    watchedAggregateType,
    watchedRelatedToId,
    watchedRelationTypeName,
  ] = form.watch([
    "conversation",
    "relatedTo.aggregate",
    "relatedTo.id",
    "relationTypeName",
  ]);
  const selectedRelationType = relationsUI.relationTypeByName(
    watchedRelationTypeName,
  );

  const [isConversationPrivate, setIsConversationPrivate] =
    useState<boolean>(false);

  useEffect(() => {
    const { aggregate, domain } = selectedRelationType;

    const isRelationGeneral = selectedRelationType.matches(
      ModelRelationType.unknown,
    );

    setIsConversationPrivate(isRelationGeneral);

    if (watchedAggregateType === aggregate) {
      return;
    }

    form.setValue("relatedTo.aggregate", aggregate);
    form.setValue("relatedTo.domain", domain);
    form.setValue("relatedTo.id", "");
    form.setValue("relatedToChild.aggregate", defaultRelatedToChild.aggregate);
    form.setValue("relatedToChild.domain", defaultRelatedToChild.domain);
    form.setValue("relatedToChild.id", "");
    form.setValue("categoryId", "");
  }, [selectedRelationType]);

  const categoryOptions = categoryListUI.getCategorySelectOptions(
    selectedRelationType,
    translate,
  );

  const showRelationSelect = !selectedRelationType.matches(
    ModelRelationType.unknown,
  );

  useEffect(() => {
    if (relatedTo?.aggregate) {
      form.setValue("relatedTo.aggregate", relatedTo.aggregate);
    }
    if (relatedTo?.aggregate) {
      form.setValue("relationTypeName", relatedTo.aggregate);
    }
    if (relatedTo?.domain) {
      form.setValue("relatedTo.domain", relatedTo.domain);
    }
    if (relatedTo?.id) {
      form.setValue("relatedTo.id", relatedTo.id);
    }
    if (relatedToChild?.aggregate) {
      form.setValue("relatedToChild.aggregate", relatedToChild.aggregate);
    }
    if (relatedToChild?.domain) {
      form.setValue("relatedToChild.domain", relatedToChild.domain);
    }
    if (relatedToChild?.id) {
      form.setValue("relatedToChild.id", relatedToChild.id);
    }
  }, [relatedTo]);

  useEffect(() => {
    if (!categoryName) {
      return;
    }

    const categoryId = categoryListUI.getCategoryIdByCategoryName(categoryName);

    if (categoryId) {
      form.setValue("categoryId", categoryId);
    } else {
      const generalCategoryId =
        categoryListUI.getCategoryIdByCategoryName("general");

      if (generalCategoryId) {
        form.setValue("categoryId", generalCategoryId);
      }
    }
  }, [categoryName]);

  const onStepChange = (step: number): void => {
    const relatedTo =
      form.watch().relatedToChild.id !== ""
        ? form.watch().relatedToChild
        : form.watch().relatedTo;
    if (step === 1) {
      if (!watchedConversation) {
        void Conversation.createNew({
          categoryId: form.watch().categoryId,
          title: "",
          relatedTo: relatedTo,
          sharedWith: !isConversationPrivate
            ? form.watch().relatedTo
            : undefined,
        }).then((createdConversation) => {
          void Conversation.loadById(createdConversation.id).then(
            (fetchedConversation) => {
              form.setValue("conversation", fetchedConversation);
            },
          );
        });
      }
    }
  };

  useEffect(() => {
    if (watchedConversation) {
      form.setFocus("title");
    }
  }, [watchedConversation]);

  return (
    <WizardModal
      form={form}
      steps={["metaData", "message"]}
      visibility={visibility}
      wizardState={wizardState}
      onCancel={() => visibility.hide()}
      onDone={() => visibility.hide()}
      onStepChange={onStepChange}
    >
      <WizardIntroStep
        description="description"
        headline="metaData.headline"
        headlineIcon={iconSupport}
        id="metaData"
        indicatorText="metaData"
      >
        <Section.Layout>
          <Section.Item headline="relatedTo">
            <SelectBox name="relationTypeName" options={relationOptions} />
            {showRelationSelect && (
              <UniversalBoundary
                errorView={InlineError}
                loadingView={<InputFieldSkeleton />}
              >
                <ConversationRelationSelect
                  relationType={selectedRelationType}
                />
              </UniversalBoundary>
            )}
            {form.watch("relatedTo.domain") !== "unknown" && (
              <SelectContainer>
                <Select
                  label="category"
                  name="categoryId"
                  options={categoryOptions}
                  placeholder="categoryPlaceholder"
                  rules={{ required: true }}
                />
                {watchedAggregateType === "project" && (
                  <Suspense>
                    <AppInstallationSelect
                      relationType={selectedRelationType}
                    />
                  </Suspense>
                )}
              </SelectContainer>
            )}
          </Section.Item>
          <Section.Item headline="visibility">
            {watchedRelationTypeName !== "unknown" && (
              <SelectBox.View
                options={[
                  {
                    title: `public-relation-${watchedRelationTypeName}`,
                    value: "public",
                  },
                  {
                    title: "private",
                    value: "private",
                  },
                ]}
                value={isConversationPrivate ? "private" : "public"}
                onChange={(value) => {
                  setIsConversationPrivate(value === "private");
                }}
              />
            )}
            <Text
              i18n={`information-${watchedRelationTypeName}-${
                isConversationPrivate ? "private" : "public"
              }`}
            />
            {!isConversationPrivate && (
              <CreateConversationAvatarStack
                relatedToId={watchedRelatedToId}
                relationTypeName={watchedRelationTypeName}
              />
            )}
          </Section.Item>
        </Section.Layout>
      </WizardIntroStep>
      <WizardStep id="message" indicatorText="message">
        <Section.Layout>
          <Section.Item headline="message.headline">
            <Text i18n="message.description" />
          </Section.Item>
          <Section.Item>
            <TextField
              autoFocus
              label="subject"
              name="title"
              placeholder="subject.placeholder"
              rules={{ maxLength: 80, required: true }}
            />
            <TextArea
              _width="100%"
              autoExpand={300}
              label="firstMessage"
              name="message"
              placeholder="firstMessage.placeholder"
              rules={{ maxLength: 8000, required: true }}
            />
            {watchedConversation && (
              <FileDropzoneComponent
                conversation={watchedConversation}
                onFilesUploaded={(fileIds) => form.setValue("fileIds", fileIds)}
              />
            )}
          </Section.Item>
        </Section.Layout>
      </WizardStep>
    </WizardModal>
  );
};

export default CreateConversation;
