import { faBallotCheck } from "@fortawesome/pro-regular-svg-icons/faBallotCheck";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { H3 } from "@mittwald/flow-components/dist/components/H3";
import PriceBox from "@mittwald/flow-components/dist/components/PriceBox";
import { PriceTable } from "@mittwald/flow-components/dist/components/PriceTable";
import { Render } from "@mittwald/flow-components/dist/components/Render";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { DateTime } from "luxon";
import React, { FC } from "react";
import { ConditionsCheckbox } from "../../../../../../components/ConditionsCheckbox/ConditionsCheckbox";
import { Domain } from "../../../../../../model/domain/Domain";
import { TldList } from "../../../../../../model/domain/TldList";
import MoneyValue from "../../../../../../model/misc/MoneyValue";
import { UserInputsRecordObject } from "../../../../../../model/misc/userInput/UserInputRecordList";
import { DateTime as DateTimeComponent } from "@mittwald/flow-components/dist/components/DateTimeText";
import Project from "../../../../../../model/project/Project";
import Box from "@mittwald/flow-components/dist/components/Box";
import { CustomerInfoBox } from "../../../../server/modals/OrderServerWizard/components/CustomerInfoBox";
import { HiddenPaymentInformation } from "../../../../server/modals/OrderServerWizard/components/HiddenPaymentInformation";
import CustomerEmailInformation from "../../../components/Order/CustomerEmailInformation/CustomerEmailInformation";
import { OwnerInformation } from "../../../components/OwnerInformation";

interface OverviewStepContentProps {
  hostname: string;
  domainOwner: UserInputsRecordObject;
}

const generalEmailInformation = <Text i18n="yearlyInvoiceGeneral" />;

export const OverviewStep: FC<OverviewStepContentProps> = (props) => {
  return (
    <WizardStep id="overview" indicatorText="overviewStep">
      <Render>
        {() => {
          const { projectId } = usePathParams("projectId");
          const { hostname } = props;
          const orderPreview = Domain.usePreviewOrder(hostname, projectId);

          const project = Project.useLoadByPathParam();
          const customerId = project.customerId;

          const tld =
            TldList.useGetSupportedTldsForOrder().useTldFromName(hostname);

          const domainPrice = new MoneyValue(
            orderPreview.domainPrice,
          ).toMoneyValueInput();

          const feePrice = new MoneyValue(
            orderPreview.feePrice,
          ).toMoneyValueInput();

          const totalPrice = new MoneyValue(
            orderPreview.totalPrice,
          ).toMoneyValueInput();

          const isCustomerMember = project.useMyMembership().data.inherited;

          const customerInfo = isCustomerMember ? (
            <CustomerInfoBox customerId={customerId} showContact={false} />
          ) : (
            <HiddenPaymentInformation />
          );
          const emailInformation = isCustomerMember ? (
            <CustomerEmailInformation customerId={customerId} />
          ) : (
            generalEmailInformation
          );

          return (
            <Section.Layout>
              <Section.Item headline="overview" headlineIcon={faBallotCheck}>
                {emailInformation}
              </Section.Item>
              <Section.Item>
                <ColumnLayout medium={[1, 1]}>
                  <Box _itemGap="l">
                    {customerInfo}
                    <OwnerInformation domainOwner={props.domainOwner} />
                  </Box>
                  <Box>
                    <H3 _mb="m" i18n="orderOverview" />
                    <PriceTable twoColumns>
                      <PriceTable.Row
                        strong={true}
                        title={{ text: `Artikel: .${tld?.name} Domain` }}
                      />
                      <PriceTable.Row
                        monthlyPrice={domainPrice}
                        strong={false}
                        title={{ text: hostname }}
                      />
                      <PriceTable.Row
                        monthlyPrice={feePrice}
                        strong={false}
                        title="setupFee"
                      />
                      <Box _mt="m">
                        <PriceBox singlePrice={totalPrice} title="total" />
                      </Box>
                      <Box _mt="s">
                        <PriceBox
                          title={{
                            id: "startingOn",
                            values: {
                              date: (
                                <DateTimeComponent
                                  format="date"
                                  value={DateTime.now().plus({
                                    months: orderPreview.domainContractDuration,
                                  })}
                                />
                              ),
                            },
                          }}
                          yearlyPrice={domainPrice}
                          yearlyPriceTitle={{
                            id:
                              orderPreview.domainContractDuration === 12
                                ? "perYear"
                                : "perTwoYears",
                          }}
                        />
                      </Box>
                    </PriceTable>
                  </Box>
                </ColumnLayout>
                <ConditionsCheckbox />
              </Section.Item>
            </Section.Layout>
          );
        }}
      </Render>
    </WizardStep>
  );
};

export default OverviewStep;
