import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import React from "react";
import TariffChangeDetailsModal from "../../../pages/app/customer/contractItems/components/TariffChangeDetailsModal";
import ContractItemTerminationModal from "../../../pages/app/customer/contractItems/termination/ContractItemTerminationModal";
import ContractItem from "../../contract/ContractItem";
import ContractItemTermination from "../../contract/ContractItemTermination";
import ContractTermination from "../../contract/ContractTermination";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";

const defaultContractActionRoles = [Role.customerOwner, Role.customerMember];

ModelActionBuilderRegistry.register({
  model: ContractItem,
  roles: defaultContractActionRoles,
  method: "terminate",
  isAvailable: (item) => item.isTerminationPossible,
  altAction: (action) => () =>
    showModal(
      "terminateServer",
      <ContractItemTerminationModal contractItem={action.model} />,
    ),
})
  .register({
    model: ContractItem,
    roles: defaultContractActionRoles,
    method: "cancelTariffChange",
    isAvailable: (item) => item.isCancelTariffChangePossible,
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "cancelTariffChange",
      },
    }),
  })
  .register({
    model: ContractItem,
    roles: defaultContractActionRoles,
    method: "showTariffChangeDetails",
    isAvailable: (item) => !!item.tariffChange,
    altAction: (action) => () =>
      showModal(
        "tariffChangeDetails",
        <TariffChangeDetailsModal tariffChange={action.model.tariffChange!} />,
      ),
  })
  .register({
    model: ContractItem,
    roles: defaultContractActionRoles,
    method: "cancelTermination",
    isAvailable: (item) => item.terminationScheduled,
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "cancelTermination",
      },
    }),
  })
  .register({
    model: ContractTermination,
    roles: defaultContractActionRoles,
    method: "cancel",
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "cancelTermination",
      },
    }),
  })
  .register({
    model: ContractItemTermination,
    roles: defaultContractActionRoles,
    method: "cancel",
    altAction: (action) => ({
      action,
      confirmation: {
        required: true,
        modalTranslationKey: "cancelTermination",
      },
    }),
  });
