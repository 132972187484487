import useFormContext from "@mittwald/flow-components/dist/components/Form/hooks/useForm/context";
import React, { FC } from "react";
import { SystemSoftwareDependency } from "../../../../../../model/app/AppInstallation";
import SystemSoftware from "../../../../../../model/app/SystemSoftware";
import SystemSoftwareVersionList from "../../../../../../model/app/SystemSoftwareVersionList";
import SystemSoftwareUI from "../../../../../../model/ui/app/SystemSoftwareUI";
import SystemSoftwareVersionSelect from "../../../components/SystemSoftwareVersionSelect";

interface Props {
  dependency: SystemSoftwareDependency;
}

const SystemSoftwareDependencySelect: FC<Props> = (props) => {
  const { dependency } = props;
  const software = SystemSoftware.useLoadById(dependency.systemSoftwareId);

  const versions =
    SystemSoftwareVersionList.useLoadBySystemSoftwareAndVersionRange(
      software.name,
      software.id,
      dependency.versionRange,
    );

  const form = useFormContext();

  form.setValue(
    `systemSoftwares.${dependency.systemSoftwareId}`,
    versions.latest().data.id,
  );

  return (
    <SystemSoftwareVersionSelect
      label={SystemSoftwareUI.of(software).getTitle()}
      name={`systemSoftwares.${dependency.systemSoftwareId}`}
      versions={versions.useItems()}
    />
  );
};

export default SystemSoftwareDependencySelect;
