import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { isDomain } from "@mittwald/flow-lib/dist/validation/domain";
import noDuplicate from "@mittwald/flow-lib/dist/validation/noDuplicate";
import React, { FC } from "react";
import { IngressList } from "../../../../../../model/domain/IngressList";

export const VHostStep: FC = () => {
  const { projectId } = usePathParams("projectId");
  const ingresses = IngressList.useLoadAllByProjectId(projectId).useItems();
  const hostnames = ingresses.map((i) => i.hostname);

  return (
    <WizardStep id="vHost" indicatorText="vHostStep">
      <Section.Layout>
        <Section.Item headline="vHost">
          <Text i18n="vHostDescription" />
        </Section.Item>
        <Section.Item>
          <TextField
            autoFocus
            label="hostname"
            name="hostname"
            rules={{
              required: true,
              validate: {
                validDomain: (value: string): boolean => isDomain(value),
                noDuplicate: noDuplicate(hostnames, {
                  convertPunycode: true,
                }),
              },
            }}
          />
        </Section.Item>
      </Section.Layout>
    </WizardStep>
  );
};

export default VHostStep;
