import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Skeleton } from "@mittwald/flow-components/dist/components/Skeleton";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import React, { FC } from "react";
import DisableForm from "../../../../../../components/DisableForm";
import { ProSpaceArticle } from "../../../../../../model/article";
import ContractItem from "../../../../../../model/contract/ContractItem";
import Bytes from "../../../../../../model/misc/Bytes";
import TariffChangePricePreviewBox from "../../../../server/modals/AdjustServerWizard/components/TariffChangePricePreviewBox";
import ConfigurationArticleSection from "../../CreateProject/components/ConfigurationArticleSection";
import ConfigurationDedicationSection from "../../CreateProject/components/ConfigurationDedicationSection";
import ConfigurationResourceSection from "../../CreateProject/components/ConfigurationResourceSection";
import ConfigurationStorageSection from "../../CreateProject/components/ConfigurationStorageSection";

interface Props {
  selectedProSpaceArticles: ProSpaceArticle[];
  selectedArticle: ProSpaceArticle;
  watchedStorageSize: Bytes;
  currentStorage: Bytes;
  liteSelected: boolean;
  currentArticle: ProSpaceArticle;
  contractItem: ContractItem;
  projectId: string;
}

export const ConfigurationStep: FC<Props> = (props) => {
  const {
    selectedArticle,
    watchedStorageSize,
    selectedProSpaceArticles,
    currentArticle,
    currentStorage,
    liteSelected,
    contractItem,
    projectId,
  } = props;

  const configHasChanged =
    !selectedArticle.isEqual(currentArticle) ||
    !currentStorage.isEqualTo(watchedStorageSize);

  return (
    <TranslationProvider
      name="proSpaceConfigurationStepContent"
      type="component"
    >
      <WizardStep
        id="configuration"
        indicatorText="configurationStep"
        infoBoxContent={
          <TariffChangePricePreviewBox
            article={selectedArticle}
            contractItem={contractItem}
            showNewPrice={configHasChanged}
            storageSize={watchedStorageSize}
          />
        }
      >
        <Section.Layout>
          <Suspense loadingView={<Skeleton _height={194} />}>
            <ConfigurationArticleSection
              currentArticle={currentArticle}
              projectId={projectId}
            />
          </Suspense>
          <ConfigurationResourceSection
            currentArticle={currentArticle}
            liteSelected={liteSelected}
            selectedProSpaceArticles={selectedProSpaceArticles}
          />
          <ConfigurationStorageSection selectedArticle={selectedArticle} />
          {!liteSelected && <ConfigurationDedicationSection />}
        </Section.Layout>
        {!configHasChanged && <DisableForm />}
      </WizardStep>
    </TranslationProvider>
  );
};

export default ConfigurationStep;
