import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconPassword } from "@mittwald/flow-icons/dist/password";
import React, { FC } from "react";

import {
  DeliveryBox,
  UpdatePasswordInputs,
} from "../../../../../model/mail/DeliveryBox";

interface Props {
  deliveryBox: DeliveryBox;
}

export const UpdateDeliveryBoxPassword: FC<Props> = (props) => {
  const visibility = useVisibilityController();
  const { deliveryBox } = props;

  const form = useForm<UpdatePasswordInputs>({
    translationKey: "changePassword",
    defaultValues: {
      password: "",
    },
    onSubmit: async (values) => {
      await deliveryBox.updatePassword(values);
    },
  });

  return (
    <DefaultModal
      _size="s"
      description="description"
      headline="updateDeliveryBoxPassword"
      headlineIcon={iconPassword}
      primary={{ action: [{ form }, visibility.hide], ok: true }}
      visibility={visibility}
    >
      <Form controller={form}>
        <TextField
          label="labelNewPassword"
          name="password"
          rules={{ required: true }}
          type="passwordWithGenerator"
        />
      </Form>
    </DefaultModal>
  );
};

export default UpdateDeliveryBoxPassword;
