import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { arrayRemoveItem } from "@mittwald/flow-app-utils/dist/demo/helpers";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { mittwaldApi } from "../../api/Mittwald";
import { Access } from "./Access";
import {
  AddPublicKeyInputs,
  NewSftpUserInputs,
  SftpUserApiData,
  UpdatePasswordInputs,
  UpdateSftpUserInputs,
} from "./types";

export class SftpUser extends Access {
  public readonly fullAccess: boolean;
  public readonly data: SftpUserApiData;

  private constructor(data: SftpUserApiData) {
    super(data);
    this.data = Object.freeze(data);
    this.fullAccess = data.accessLevel === "full";
  }

  public static fromApiData(data: SftpUserApiData): SftpUser {
    return new SftpUser(data);
  }

  public static useLoadById(id: string): SftpUser {
    const data = mittwaldApi.sftpUserGetSftpUser
      .getResource({
        path: { sftpUserId: id },
      })
      .useWatchData();
    return SftpUser.fromApiData(data);
  }

  public static useLoadByPathParam(): SftpUser {
    const { sftpUserId } = usePathParams("sftpUserId");
    return SftpUser.useLoadById(sftpUserId);
  }

  public static async createNew(
    values: NewSftpUserInputs,
    projectId: string,
  ): Promise<SftpUser | "noDirectories" | "invalidKey"> {
    const result = await mittwaldApi.sftpUserCreateSftpUser.request({
      path: { projectId: projectId },
      requestBody: {
        description: values.description,
        authentication:
          values.authType === "publicKey"
            ? { publicKeys: [{ key: values.key, comment: values.comment }] }
            : { password: values.password },
        accessLevel: values.accessLevel,
        directories: values.directories.map((d) => d.path),
        expiresAt: values.expiresAt,
      },
    });

    if (result.status === 400) {
      if (result.content.message?.includes("directories is required")) {
        return "noDirectories";
      } else if (
        result.content.message?.includes("publicKey is not a valid sshKey")
      ) {
        return "invalidKey";
      }
    }

    assertStatus(result, 201);

    return this.fromApiData(result.content);
  }

  public async update(values: UpdateSftpUserInputs): Promise<void | false> {
    const result = await mittwaldApi.sftpUserUpdateSftpUser.request({
      path: { sftpUserId: this.id },
      requestBody: {
        description: values.description,
        accessLevel: values.accessLevel,
        directories: values.directories?.map((d) => d.path),
        active: values.active,
        expiresAt: values.expiresAt,
      },
    });

    if (
      result.status === 400 &&
      result.content.validationErrors.find((e) => e.path === "user.directories")
    ) {
      return false;
    }

    assertStatus(result, 204);
  }

  public async updatePassword(values?: UpdatePasswordInputs): Promise<void> {
    const response = await mittwaldApi.sftpUserUpdateSftpUser.request({
      path: {
        sftpUserId: this.id,
      },
      requestBody: {
        password: values?.password ?? "",
      },
    });

    assertStatus(response, 204);
  }

  public async deletePassword(): Promise<void> {
    await this.updatePassword();
  }

  public async addPublicKey(values: AddPublicKeyInputs): Promise<void | false> {
    const response = await mittwaldApi.sftpUserUpdateSftpUser.request({
      path: {
        sftpUserId: this.id,
      },
      requestBody: {
        publicKeys: this.data.publicKeys
          ? [
              ...this.data.publicKeys,
              { key: values.key, comment: values.comment },
            ]
          : [{ key: values.key, comment: values.comment }],
      },
    });

    if (
      response.status === 400 &&
      response.content.message?.includes("publicKey is not a valid sshKey")
    ) {
      return false;
    }

    assertStatus(response, 204);
  }

  public async toggleActive(): Promise<void> {
    const result = await mittwaldApi.sftpUserUpdateSftpUser.request({
      path: { sftpUserId: this.id },

      requestBody: { active: !this.active },
    });
    assertStatus(result, 204);
  }

  public async deletePublicKey(publicKey: string): Promise<void> {
    arrayRemoveItem(this.publicKeyList.items, (p) => p.key === publicKey);

    const result = await mittwaldApi.sftpUserUpdateSftpUser.request({
      path: { sftpUserId: this.id },

      requestBody: {
        publicKeys: this.publicKeyList.items,
      },
    });

    assertStatus(result, 204);
  }

  public async deleteSftpUser(): Promise<void> {
    const result = await mittwaldApi.sftpUserDeleteSftpUser.request({
      path: { sftpUserId: this.id },
    });

    assertStatus(result, 204);
  }
}
