import Form, { useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import { WizardModal } from "@mittwald/flow-components/dist/components/WizardModal";
import { useValueController } from "@mittwald/flow-components/dist/hooks/useValueController";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconProject } from "@mittwald/flow-icons/dist/project";
import invariant from "invariant";
import React, { FC, useEffect, useMemo, useState } from "react";
import { ArticleFactory } from "../../../../../model/article";
import ProSpaceArticle, {
  OrderProSpaceInput,
  ProSpaceArticleTag,
  ProSpaceResourceTag,
} from "../../../../../model/article/ProSpaceArticle";
import Customer, {
  CustomerUpdateInputs,
} from "../../../../../model/customer/Customer";
import { CustomerList } from "../../../../../model/customer/CustomerList";
import {
  InvoiceSettings,
  InvoiceSettingsInputs,
  InvoiceSettingsPaymentInput,
} from "../../../../../model/customer/InvoiceSettings";
import Bytes from "../../../../../model/misc/Bytes";
import SpaceServerProject from "../../../../../model/project/SpaceServerProject";
import Server from "../../../../../model/server/Server";
import CustomerListUI from "../../../../../model/ui/customer/CustomerListUI";
import OrderCompleteConfetti from "../../../components/OrderCompleteConfetti";
import ContractPartnerStep from "../../../server/modals/OrderServerWizard/components/ContractPartnerStep";
import { CustomerStep } from "../../../server/modals/OrderServerWizard/components/CustomerStep";
import OverviewStep from "../../../server/modals/OrderServerWizard/components/OverviewStep";
import PaymentStep from "../../../server/modals/OrderServerWizard/components/PaymentStep";
import { RecipientStep } from "../../../server/modals/OrderServerWizard/components/RecipientStep";
import { CustomerStepFields } from "../../../server/types";
import ArticleTypeProjectDetailsSection from "./components/ArticleTypeProjectDetailsSection";
import ArticleTypeStep from "./components/ArticleTypeStep";
import ConfigurationStep from "./components/ConfigurationStep";

export interface OrderProSpaceFormFields extends OrderProSpaceInput {
  proSpaceOrSpaceServer: "proSpace" | "spaceServer";
  articleFilter: ProSpaceArticleTag;
  resourceFilter: ProSpaceResourceTag;
  dedicated: boolean;
  articleId: string;
  serverId: string;
  useFreeTrial: boolean;
}

interface Props {
  server?: Server;
  customer?: Customer;
}

export const CreateProject: FC<Props> = (props) => {
  const modalVisibility = useVisibilityController();

  /* create project */

  const proSpaceArticles: ProSpaceArticle[] =
    ArticleFactory.useLoadAllProSpaceArticles();
  const defaultSelectedArticle = proSpaceArticles.filter(
    (a) => a.articleTag === ProSpaceArticleTag.proSpace,
  )[0];
  invariant(defaultSelectedArticle, "no proSpace articles available");

  let selectedArticle = defaultSelectedArticle;

  const [showConfetti, setShowConfetti] = useState(false);

  const createProjectForm = useForm<OrderProSpaceFormFields>({
    defaultValues: {
      dedicated: false,
      articleFilter: ProSpaceArticleTag.proSpace,
      description: "",
      serverId: props.server?.id,
      storageSizeInBytes:
        defaultSelectedArticle.baseStorageAttribute.bytes.bytes,
      articleId: defaultSelectedArticle.id,
      resourceFilter: ProSpaceResourceTag.cpu,
      customerId: props.customer?.id,
      proSpaceOrSpaceServer: "proSpace",
      useFreeTrial: false,
    },
    onSubmit: async (values) => {
      values.proSpaceOrSpaceServer === "proSpace" && !props.server
        ? await selectedArticle.order(values).then(() => setShowConfetti(true))
        : await SpaceServerProject.createNew(values);
    },
  });

  const [
    watchedArticleFilter,
    watchedDedicated,
    watchedProSpaceOrSpaceServer,
    watchedStorageSizeInBytes,
    watchedResourceFilter,
    watchedArticleId,
    watchedCustomerId,
    watchedUseFreeTrial,
  ] = createProjectForm.watch([
    "articleFilter",
    "dedicated",
    "proSpaceOrSpaceServer",
    "storageSizeInBytes",
    "resourceFilter",
    "articleId",
    "customerId",
    "useFreeTrial",
  ]);
  const watchedStorageSize = Bytes.of(watchedStorageSizeInBytes, "byte");
  const proSpaceSelected = watchedProSpaceOrSpaceServer === "proSpace";
  const spaceServerSelected = watchedProSpaceOrSpaceServer === "spaceServer";
  const liteSelected = watchedArticleFilter === ProSpaceArticleTag.proSpaceLite;
  const dedicatedSelected =
    watchedArticleFilter === ProSpaceArticleTag.proSpace && watchedDedicated;
  const selectedProSpaceArticles = proSpaceArticles
    .filter((a) =>
      liteSelected
        ? a.articleTag === ProSpaceArticleTag.proSpaceLite
        : dedicatedSelected
          ? a.articleTag === ProSpaceArticleTag.proSpaceDedicated
          : a.articleTag === ProSpaceArticleTag.proSpace,
    )
    .filter((a) => liteSelected || watchedResourceFilter === a.resourceTag);

  const newSelectedArticle = proSpaceArticles.find(
    (a) => a.id === watchedArticleId,
  );
  if (newSelectedArticle) {
    selectedArticle = newSelectedArticle;
  }

  useEffect(() => {
    const articleWithSameSpecs = selectedProSpaceArticles.find(
      (a) => a.machineType.cpu === selectedArticle.machineType.cpu,
    );
    const firstSelectedArticleId = selectedProSpaceArticles[0]?.id;
    if (firstSelectedArticleId) {
      createProjectForm.setValue(
        "articleId",
        articleWithSameSpecs?.id ?? firstSelectedArticleId,
      );
    }
  }, [watchedDedicated, watchedResourceFilter]);

  useEffect(() => {
    const firstSelectedArticleId = selectedProSpaceArticles[0]?.id;
    if (firstSelectedArticleId) {
      createProjectForm.setValue("articleId", firstSelectedArticleId);
    }
  }, [watchedArticleFilter]);

  /* select or create customer */

  const customerList = CustomerList.useAll();
  const customersAllowedToOrder = customerList.getCustomersAllowedToOrder();
  const customerSelectOptions =
    CustomerListUI.of(customerList).useSelectOptions();
  const existingCustomersAvailable = customerSelectOptions.length > 0;
  const customerModeController = useValueController(
    existingCustomersAvailable ? "existing" : "new",
  );
  const customerMode = customerModeController.watch();

  const customerForm = useForm<CustomerStepFields>({
    showSuccessFeedback: false,
    translationKey: "addCustomer",
    defaultValues: {
      id: props.customer?.id,
      name: "",
    },
    onSubmit: async (values) => {
      const { name, id } = values;

      if (customerModeController.value.current === "new") {
        invariant(name !== undefined, "Invalid state");

        const response = await Customer.createNew({ name });

        createProjectForm.setValue("customerId", response.id);
        customerModeController.updateValue("existing");
      } else {
        invariant(id !== undefined, "Invalid state");
        createProjectForm.setValue("customerId", id);
      }
    },
  });

  const selectedCustomerId = customerForm.watch("id");
  const selectedCustomer = customersAllowedToOrder.find(
    (c) => c.id === selectedCustomerId,
  );
  const hideContractPartnerStep = useMemo(() => {
    const selectedCustomerHasOwner = !!selectedCustomer?.contact;
    return customerMode === "existing" && selectedCustomerHasOwner;
  }, [selectedCustomerId, customerMode]);

  useEffect(() => {
    customerForm.setValue("id", watchedCustomerId);
  }, [watchedCustomerId]);

  /* update contract partner */

  const updateContractPartnerForm = useForm<CustomerUpdateInputs>({
    defaultValues: {
      owner: {
        salutation: "other",
      },
    },
    showSuccessFeedback: false,
    translationKey: "updateContractPartner",
    onSubmit: async (values) => {
      const customer = await Customer.loadById(watchedCustomerId);
      await customer.update(values);
    },
  });

  /* update payment method */

  const paymentForm = useForm<
    InvoiceSettingsInputs & {
      otherRecipient: boolean;
    }
  >({
    defaultValues: {
      paymentSettings: {
        method: "invoice",
        iban: "",
      },
      invoicePeriod: 1,
      invoiceRecipient: "contractPartner",
    },
    onSubmit: async (values) => {
      selectedCustomerId &&
        (await InvoiceSettings.update(values, selectedCustomerId));
    },
  });

  const otherRecipient = paymentForm.watch("otherRecipient");
  const [
    watchedPaymentMethod,
    watchedPaymentIban,
    watchedPaymentBic,
    watchedPaymentAccountHolder,
    watchedPaymentConfirmDebit,
  ] = paymentForm.watch([
    "paymentSettings.method",
    "paymentSettings.iban",
    "paymentSettings.bic",
    "paymentSettings.accountHolder",
    "paymentSettings.confirmDebit",
  ]);
  const watchedPaymentData: InvoiceSettingsPaymentInput = {
    iban: watchedPaymentIban,
    bic: watchedPaymentBic,
    accountHolder: watchedPaymentAccountHolder,
    method: watchedPaymentMethod,
    confirmDebit: watchedPaymentConfirmDebit,
  };

  const articleTypeStep = (
    <ArticleTypeStep
      customer={props.customer}
      server={props.server}
      spaceServerSelected={spaceServerSelected}
    />
  );

  const configurationStep = (
    <ConfigurationStep
      freeTrial={watchedUseFreeTrial}
      selectedArticle={selectedArticle}
      selectedProSpaceArticles={selectedProSpaceArticles}
      watchedStorageSize={watchedStorageSize}
    />
  );

  const customerStep = (
    <CustomerStep
      customerMode={customerMode}
      customerModeController={customerModeController}
      customerSelectOptions={customerSelectOptions}
      existingCustomersAvailable={existingCustomersAvailable}
      form={customerForm}
    />
  );

  const contractPartnerStep = (
    <ContractPartnerStep form={updateContractPartnerForm} />
  );

  const paymentStep = <PaymentStep form={paymentForm} />;

  const recipientStep = (
    <RecipientStep
      watchedCustomerId={watchedCustomerId}
      watchedPaymentData={watchedPaymentData}
    />
  );

  const overviewStep = (
    <OverviewStep
      customerId={watchedCustomerId}
      freeTrial={watchedUseFreeTrial}
      selectedArticle={selectedArticle}
      storageSize={watchedStorageSize}
    />
  );

  return props.server ? (
    <DefaultModal
      description="description"
      headline="createProject"
      headlineIcon={iconProject}
      primary={{
        action: [{ form: createProjectForm }, modalVisibility.hide],
        ok: true,
      }}
      visibility={modalVisibility}
    >
      <Form controller={createProjectForm}>
        <ArticleTypeProjectDetailsSection
          customer={props.customer}
          server={props.server}
          spaceServerSelected
        />
      </Form>
    </DefaultModal>
  ) : (
    <WizardModal
      _size="l"
      form={createProjectForm}
      steps={[
        "articleType",
        "configuration",
        "customer",
        "contractPartner",
        "payment",
        "recipient",
        "overview",
      ]}
    >
      {showConfetti && <OrderCompleteConfetti />}
      {articleTypeStep}
      {proSpaceSelected && configurationStep}
      {proSpaceSelected && !props.customer && customerStep}
      {proSpaceSelected && !hideContractPartnerStep && contractPartnerStep}
      {proSpaceSelected && !hideContractPartnerStep && paymentStep}
      {proSpaceSelected && otherRecipient && recipientStep}
      {proSpaceSelected && overviewStep}
    </WizardModal>
  );
};

export default CreateProject;
