import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { usePromise } from "@mittwald/react-use-promise";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import { MarketplaceContext } from "./Extension";
import ExtensionInstance from "./ExtensionInstance";

class ExtensionInstanceList extends ListModel<ExtensionInstance> {
  public static useLoadAll(
    context: MarketplaceContext,
    contextId: string,
  ): ExtensionInstanceList {
    const response = usePromise(
      mittwaldApi.extensionListExtensionInstances.request,
      [{ query: { context, contextId } }],
      {
        tags: ["extension-instance-list"],
        autoRefresh: { seconds: 30 },
      },
    );

    assertStatus(response, 200);

    const instances = response.content.map(
      (instance) => new ExtensionInstance(instance),
    );

    return new ExtensionInstanceList(instances);
  }
}

export default ExtensionInstanceList;
