import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { ConfirmAction } from "@mittwald/flow-components/dist/lib/actions/ConfirmAction";
import { iconDelete } from "@mittwald/flow-icons/dist/delete";

import React from "react";
import AdjustProSpaceProject from "../../../pages/app/project/modals/AdjustProSpaceProject/AdjustProSpaceProject";
import CreateProject from "../../../pages/app/project/modals/CreateProject/CreateProject";
import DeleteProject from "../../../pages/app/project/modals/DeleteProject/DeleteProject";
import InviteProjectMember from "../../../pages/app/project/modals/InviteProjectMember/InviteProjectMember";
import UpdateProjectAvatar from "../../../pages/app/project/modals/UpdateProjectAvatar/UpdateProjectAvatar";
import {
  ProjectBase,
  ProSpaceProject,
  SpaceServerProject,
} from "../../project";
import { Role } from "../acl/Role";
import { ModelActionBuilderRegistry } from "../ModelActionBuilderRegistry";
import CreateDomain from "../../../pages/app/domain/modals/CreateDomain/CreateDomain";

ModelActionBuilderRegistry.register({
  model: [ProjectBase, SpaceServerProject, ProSpaceProject],
  roles: [Role.projectAdmin],
  method: "deleteAvatar",
  altAction: (action) =>
    new ConfirmAction(action, {
      modalTranslationKey: "deleteAvatar",
      modalProps: {
        headlineIcon: iconDelete,
      },
    }),
})
  .register({
    model: [ProjectBase, SpaceServerProject, ProSpaceProject],
    roles: [Role.projectAdmin],
    method: "requestAvatarUpload",
    altAction: (action) => () =>
      showModal(
        "updateProjectAvatar",
        <UpdateProjectAvatar project={action.model} />,
      ),
  })
  .register({
    model: [ProjectBase, SpaceServerProject, ProSpaceProject],
    roles: [Role.projectAdmin, Role.projectDeveloper],
    method: "createIngress",
    altAction: () => () => showModal("createDomain", <CreateDomain />),
  })
  .register({
    model: SpaceServerProject,
    roles: [Role.projectAdmin],
    method: "delete",
    isAvailable: (project) => !!project.serverId,
    altAction: (action) => () =>
      showModal("deleteProject", <DeleteProject project={action.model} />),
  })
  .register({
    model: [ProjectBase, SpaceServerProject, ProSpaceProject],
    roles: [Role.projectAdmin],
    method: "inviteMember",
    altAction: (action) => () =>
      showModal(
        "inviteProjectMember",
        <InviteProjectMember project={action.model} />,
      ),
  })
  .register({
    model: SpaceServerProject,
    roles: [Role.anybody],
    method: "createNew",
    altAction: () => () => showModal("createProject", <CreateProject />),
  })
  .register({
    model: ProSpaceProject,
    roles: [Role.projectAdmin],
    method: "adjust",
    isAvailable: (project) =>
      project.useMyMembership().data.inherited &&
      project.useContract().isTariffChangePossible,
    altAction: (action) => () =>
      showModal(
        "adjustProSpaceProject",
        <AdjustProSpaceProject project={action.model} />,
      ),
  });
