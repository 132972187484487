import { FormController } from "@mittwald/flow-components/dist/components/Form";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer";
import { Render } from "@mittwald/flow-components/dist/components/Render";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import TextLink from "@mittwald/flow-components/dist/components/TextLink";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { VisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import React, { FC } from "react";
import { NewDomainInputs } from "../../../../../../model/domain/Domain";
import { DomainPriceList } from "../../../../../../model/domain/prices/DomainPriceList";
import DomainUI from "../../../../../../model/ui/domain/DomainUI";
import CreateDomain from "../CreateDomain";
import { SelectGeneratedDomainSection } from "./SelectGeneratedDomainSection";

interface Props {
  form: FormController<NewDomainInputs>;
  modalVisibility: VisibilityController;
}
export const BookDomainStep: FC<Props> = (props) => {
  const { form, modalVisibility } = props;

  const domainCheckerUsed: string = form.watch("domainCheckerUsed");
  const [disablePrimaryAction, setDisablePrimaryAction] =
    React.useState<boolean>(false);
  const domainPrices = DomainPriceList.useLoadAllmStudio().useItems();

  const domainNotAvailable =
    form.formState.errors.domain &&
    form.formState.errors.domain.message == "domainNotAvailable";

  const watchedDomain = form.watch("domain");
  return (
    <WizardStep
      disabledPrimaryAction={disablePrimaryAction}
      id="bookDomain"
      indicatorText="bookDomainStep"
    >
      <Render>
        {() => {
          return (
            <Section.Layout>
              <Section.Item headline="bookDomain">
                <Text i18n="bookDomainDescription" />
                <SelectBox
                  name="domainCheckerUsed"
                  options={[
                    { title: "enterDomain", value: "false" },
                    {
                      title: "generateDomain",
                      value: "true",
                    },
                  ]}
                  value={domainCheckerUsed}
                />
                <Text
                  i18n={{
                    id: "availableTlds",
                    values: {
                      domainPage: (
                        <TextLink
                          _inline
                          action="https://www.mittwald.de/produkte/domains"
                          i18n="domainPage"
                        />
                      ),
                    },
                  }}
                />
              </Section.Item>
              {domainCheckerUsed === "true" && (
                <SelectGeneratedDomainSection
                  domainForm={props.form}
                  domainPrices={domainPrices}
                  setDisablePrimaryAction={setDisablePrimaryAction}
                />
              )}
              {domainCheckerUsed === "false" && (
                <Section.Item>
                  <TextField
                    autoFocus
                    label="hostname"
                    name="domain"
                    rules={{
                      required: true,
                      validate: DomainUI.validateDomainForOrder,
                    }}
                  />
                  {domainNotAvailable && (
                    <TextLink
                      action={() => {
                        modalVisibility.hide();
                        showModal(
                          "createDomain",
                          <TranslationProvider name="createDomain" type="modal">
                            <CreateDomain
                              presetDomainForMove={watchedDomain.toString()}
                            />
                          </TranslationProvider>,
                        );
                      }}
                      i18n="moveDomain"
                    />
                  )}
                </Section.Item>
              )}
            </Section.Layout>
          );
        }}
      </Render>
    </WizardStep>
  );
};

export default BookDomainStep;
