import NewFlag from "@mittwald/flow-components/dist/components/NewFlag";
import Section from "@mittwald/flow-components/dist/components/Section";
import Text from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";

export const ConfirmationStepIntro: FC = () => {
  return (
    <Section.Item
      headline={{
        id: "confirmation",
        values: {
          beta: <NewFlag _inline />,
        },
      }}
    >
      <Text i18n="confirmationDescription" />
    </Section.Item>
  );
};

export default ConfirmationStepIntro;
