import Avatar from "@mittwald/flow-components/dist/components/Avatar/Avatar";
import {
  Initial,
  InitialsOverlay,
} from "@mittwald/flow-components/dist/components/Avatar/styled";
import { color } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import styled from "styled-components";

export const RestAvatar = styled(Avatar)`
  ${InitialsOverlay} {
    background: ${color.base.hostingBlue.$25};

    ${Initial} {
      color: ${color.base.hostingBlue.$900};
    }
  }
`;
