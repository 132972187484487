import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { DateTime } from "luxon";
import z from "zod";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import ModelRelation from "../misc/modelRelation/ModelRelation";
import modelRelationFactory from "../misc/modelRelation/modelRelationFactory";

export type NotificationApiSchema =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Messaging_Notification;

const notificationApiSchema = z.object({
  type: z.string(),
  id: z.string(),
  createdAt: z.string(),
  severity: z.union([
    z.literal("success"),
    z.literal("info"),
    z.literal("warning"),
    z.literal("error"),
  ]),
  read: z.boolean(),
  reference: z.object({
    domain: z.string(),
    aggregate: z.string(),
    id: z.string(),
    parents: z
      .array(
        z.object({
          domain: z.string(),
          aggregate: z.string(),
          id: z.string(),
        }),
      )
      .optional(),
  }),
});

export class Notification {
  public readonly id: string;
  public readonly data: NotificationApiSchema;
  public readonly relation: ModelRelation;
  public readonly createdAt?: DateTime;
  public readonly read: boolean;

  private constructor(data: NotificationApiSchema) {
    this.id = data.id;
    this.data = Object.freeze(data);
    this.relation = modelRelationFactory(data.reference);
    this.createdAt = data.createdAt
      ? DateTime.fromISO(data.createdAt)
      : undefined;
    this.read = data.read;
  }

  public static fromApiData(data: NotificationApiSchema): Notification {
    return new Notification(notificationApiSchema.parse(data));
  }

  public async markAsRead(): Promise<void> {
    const response = await mittwaldApi.notificationsReadNotification.request({
      path: { notificationId: this.id },
      requestBody: { status: "read" },
    });
    assertStatus(response, 200);
  }
}

export default Notification;
