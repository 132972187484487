import {
  PrimaryActionBarAction,
  SecondaryActionBarAction,
} from "@mittwald/flow-components/dist/components/ActionBar";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import Breakpoints from "@mittwald/flow-components/dist/components/Breakpoints";
import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { Form, useForm } from "@mittwald/flow-components/dist/components/Form";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import NumberField from "@mittwald/flow-components/dist/components/NumberField/NumberField";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { iconEmail } from "@mittwald/flow-icons/dist/email";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { isEmail } from "@mittwald/flow-lib/dist/validation";
import React, { FC, useRef } from "react";
import { IngressList } from "../../../../../model/domain/IngressList";
import EmailAddress, {
  NewEmailAddressInputs,
} from "../../../../../model/mail/EmailAddress";
import { EmailAddressList } from "../../../../../model/mail/EmailAddressList";
import CatchAllExistsConfirmation from "../../addresses/components/CatchAllExistsConfirmation";
import EmailAddressForm from "../../addresses/components/EmailAddressForm";

export const CreateEmailAddress: FC = () => {
  const { projectId } = usePathParams("projectId");
  const goto = useGotoLink();
  const createMore = useRef<boolean>();
  const createdEmailAddressId = useRef<string>();
  const visibility = useVisibilityController();

  const emailAddressList = EmailAddressList.useLoadAllByProjectId(projectId);

  const enabledIngresses =
    IngressList.useLoadAllByProjectId(projectId).useEnabledIngressList();

  const defaultValues = {
    address: undefined,
    isCatchAll: false,
    mailbox: {
      password: undefined,
      storage: 2,
      enableSpamProtection: true,
    },
  };

  const form = useForm<NewEmailAddressInputs, string>({
    translationKey: "createEmailAddress",
    defaultValues,
    onSubmit: async (values) => {
      if (emailAddressList.addressExists(values.address)) {
        form.setError("address", {
          message: "addressExists",
        });
        return false;
      }

      const domain = EmailAddress.getEmailAddressDomainPart(values.address);

      const existingCatchAllAddress =
        emailAddressList.findCatchAllAddressForDomain(domain);

      if (existingCatchAllAddress && values.isCatchAll) {
        await existingCatchAllAddress.updateCatchAll(false);
      }

      const createdId = await EmailAddress.createNewEmailAddress(
        values,
        projectId,
      );

      if (createdId === "isCatchAll") {
        form.setError(
          "isCatchAll",
          {
            message: "catchAllExists",
          },
          { shouldFocus: true },
        );
        return false;
      }

      createdEmailAddressId.current = createdId;
    },
  });

  const afterSubmit = (): void => {
    if (!createMore.current) {
      visibility.hide();
      if (createdEmailAddressId.current) {
        goto(
          "mailAddress",
          { emailAddressId: createdEmailAddressId.current },
          { replace: true },
        );
      }
      return;
    }
    createMore.current = false;
    form.setFocus("address");
    form.reset(defaultValues);
  };

  const [address, isCatchAll] = form.watch(["address", "isCatchAll"]);

  const domain = EmailAddress.getEmailAddressDomainPart(address);

  const existingCatchAllAddress =
    emailAddressList.findCatchAllAddressForDomain(domain);

  const ingressesAvailable = enabledIngresses.items.length > 0;

  const newAddressPrimaryAction: PrimaryActionBarAction = {
    text: "create",
    ok: true,
    disabled: !ingressesAvailable,
    action: [{ form }, afterSubmit],
  };

  const newAddressSecondaryAction: SecondaryActionBarAction = {
    text: "createAndNew",
    disabled: !ingressesAvailable,
    action: [
      () => {
        createMore.current = true;
      },
      { form },
      afterSubmit,
    ],
  };

  return (
    <DefaultModal
      description="description"
      headline="createEmailAddress"
      headlineIcon={iconEmail}
      primary={newAddressPrimaryAction}
      secondary={newAddressSecondaryAction}
      visibility={visibility}
    >
      <Form controller={form}>
        <Section.Layout>
          <Section.Item headline="address">
            <ColumnLayout medium={[1, 1]}>
              <EmailAddressForm
                autoFocus
                catchAll={isCatchAll}
                ingressList={enabledIngresses}
                name="address"
              />
              <TextField
                autoComplete="new-password"
                disabled={!ingressesAvailable}
                label="labelPassword"
                name="mailbox.password"
                rules={{ required: true }}
                type="passwordWithGenerator"
              />
            </ColumnLayout>
          </Section.Item>
          <Section.Item headline="storage">
            <Text i18n="description" />
            <ColumnLayout medium={[1, 1]}>
              <NumberField
                decimals={1}
                disabled={!ingressesAvailable}
                label="labelStorage"
                name="mailbox.storage"
                rules={{
                  required: true,
                  min: EmailAddress.minMailboxSizeGB,
                  max: EmailAddress.maxMailboxSizeGB,
                }}
                step={EmailAddress.mailboxSizeStepGB}
                textSuffix="gb"
              />
            </ColumnLayout>
          </Section.Item>
          <Section.Item headline="additionalOptions">
            <Breakpoints.Div breakpoints={[500]}>
              <Box _flexDirection={["column", "row"]} _itemGap="xl">
                <CheckBox
                  disabled={!ingressesAvailable}
                  name="mailbox.enableSpamProtection"
                  title="labelSpamProtection"
                  uncheckConfirmationOptions={{
                    required: true,
                    modalTranslationKey: "uncheckSpamProtection",
                  }}
                />
                <CheckBox
                  checkConfirmationOptions={{
                    required: !isCatchAll && !!existingCatchAllAddress,
                    modalComponent: CatchAllExistsConfirmation,
                    modalTranslationValues: {
                      existingCatchAllAddress: existingCatchAllAddress?.address,
                    },
                    modalTranslationKey: "enableCatchAll",
                  }}
                  disabled={!ingressesAvailable || !isEmail(address)}
                  name="isCatchAll"
                  title="labelCatchAll"
                />
              </Box>
            </Breakpoints.Div>
          </Section.Item>
        </Section.Layout>
      </Form>
    </DefaultModal>
  );
};

export default CreateEmailAddress;
