import { DateTime } from "@mittwald/flow-components/dist/components/DateTimeText";
import Translate from "@mittwald/flow-components/dist/components/Translate";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import React, { ReactElement } from "react";
import { AppModelRelation } from "../../app/AppModelRelation";
import { BackupModelRelation } from "../../backup/BackupModelRelation";
import { RegistryModelRelation } from "../../container/RegistryModelRelation";
import CustomerInviteModelRelation from "../../customer/CustomerInviteModelRelation";
import CustomerModelRelation from "../../customer/CustomerModelRelation";
import Domain from "../../domain/Domain";
import { DomainModelRelation } from "../../domain/DomainModelRelation";
import { IngressModelRelation } from "../../domain/IngressModelRelation";
import { SSLCertificateModelRelation } from "../../domain/ssl/SSLCertificateModelRelation";
import { InvoiceModelRelation } from "../../invoice/InvoiceModelRelation";
import EmailAddressModelRelation from "../../mail/EmailAddressModelRelation";
import ModelRelation from "../../misc/modelRelation/ModelRelation";
import { OrderModelRelation } from "../../order/OrderModelRelation";
import ProjectInviteModelRelation from "../../project/ProjectInviteModelRelation";
import ProjectModelRelation from "../../project/ProjectModelRelation";
import ServerModelRelation from "../../server/ServerModelRelation";
import ConversationModelRelation from "../../support/ConversationModelRelation";
import { Registry } from "../../container/Registry";

export class ModelRelationUI {
  public readonly modelRelation: ModelRelation;

  private constructor(modelRelation: ModelRelation) {
    this.modelRelation = modelRelation;
  }

  public static of(modelRelation: ModelRelation): ModelRelationUI {
    return new ModelRelationUI(modelRelation);
  }

  public useTargetText(): I18nDefinition {
    const relation = this.modelRelation;

    if (relation instanceof ProjectModelRelation) {
      const project = relation.useOptionalProject();
      return project ? { text: project.data.description } : undefined;
    } else if (relation instanceof SSLCertificateModelRelation) {
      const certificate = relation.useOptionalSSLCertificate();
      return certificate ? { text: certificate.name } : undefined;
    } else if (relation instanceof CustomerModelRelation) {
      const customer = relation.useOptionalCustomer();
      return customer ? { text: customer.data.name } : undefined;
    } else if (relation instanceof ServerModelRelation) {
      const server = relation.useOptionalServer();
      return server ? { text: server.data.description } : undefined;
    } else if (relation instanceof EmailAddressModelRelation) {
      const emailAddress = relation.useOptionalMail();
      return emailAddress ? { text: emailAddress.data.address } : undefined;
    } else if (relation instanceof AppModelRelation) {
      const appInstallation = relation.useOptionalAppInstallation();
      return appInstallation
        ? { text: appInstallation.data.description }
        : undefined;
    } else if (relation instanceof IngressModelRelation) {
      const ingress = relation.useOptionalIngress();
      return ingress ? { text: ingress.hostname } : undefined;
    } else if (relation instanceof DomainModelRelation) {
      const domain = relation.useOptionalDomain();
      return domain ? { text: domain.domain } : undefined;
    } else if (relation instanceof ConversationModelRelation) {
      const conversation = relation.useOptionalConversation();
      return conversation ? { text: conversation.data.title } : undefined;
    } else if (relation instanceof ProjectInviteModelRelation) {
      const invite = relation.useOptionalInvite();
      return invite ? { text: invite.data.projectDescription } : undefined;
    } else if (relation instanceof CustomerInviteModelRelation) {
      const invite = relation.useOptionalInvite();
      return invite ? { text: invite.data.customerName } : undefined;
    } else if (relation instanceof InvoiceModelRelation) {
      const invoice = relation.useOptionalInvoice();
      return invoice ? { text: invoice.data.invoiceNumber } : undefined;
    } else if (relation instanceof OrderModelRelation) {
      const order = relation.useOptionalOrder();
      return order ? { text: order.data.orderNumber } : undefined;
    } else if (relation instanceof BackupModelRelation) {
      const backup = relation.useOptionalBackup();
      return !backup ? undefined : backup.data.description ? (
        { text: backup.data.description }
      ) : (
        <DateTime value={backup.data.createdAt} />
      );
    } else if (relation instanceof RegistryModelRelation) {
      const registry = relation.useOptionalRegistry();
      return registry ? { text: registry.description } : undefined;
    } else {
      return undefined;
    }
  }

  public useDetailsLink(): string | undefined {
    const buildLink = useLinkBuilder();
    const relation = this.modelRelation;

    if (!this.useTargetText()) {
      return undefined;
    }

    if (relation instanceof SSLCertificateModelRelation) {
      const certificate = relation.useOptionalSSLCertificate();
      if (certificate) {
        return buildLink("sslCertificate", {
          projectId: certificate.data.projectId,
          certificateId: relation.id,
        });
      }
    }

    if (relation instanceof ProjectModelRelation) {
      return buildLink("project", { projectId: relation.id });
    }

    if (relation instanceof CustomerModelRelation) {
      return buildLink("customerDetails", { customerId: relation.id });
    }

    if (relation instanceof ServerModelRelation) {
      return buildLink("server", { serverId: relation.id });
    }

    if (relation instanceof EmailAddressModelRelation) {
      const emailAddress = relation.useOptionalMail();

      if (!emailAddress) {
        return undefined;
      }

      return buildLink("mailAddress", {
        emailAddressId: relation.id,
        projectId: emailAddress.data.projectId,
      });
    }

    if (relation instanceof AppModelRelation) {
      const appInstallation = relation.useOptionalAppInstallation();

      if (!appInstallation) {
        return undefined;
      }
      return buildLink("appOverview", {
        appInstallationId: relation.id,
        projectId: appInstallation.projectId,
      });
    }

    if (relation instanceof IngressModelRelation) {
      const ingress = relation.useOptionalIngress();
      const projectId = ingress?.data.projectId;
      const ingressId = ingress?.id;

      if (!projectId || !ingressId) {
        return undefined;
      }

      return buildLink("domain", { projectId, ingressId });
    }

    if (relation instanceof DomainModelRelation) {
      const domain = Domain.useTryLoadById(relation.id);
      const projectId = domain?.data.projectId;

      if (!projectId) {
        return undefined;
      }

      return buildLink("domainOverview", { projectId });
    }

    if (relation instanceof ConversationModelRelation) {
      const conversation = relation.useOptionalConversation();

      if (!conversation) {
        return;
      }

      return buildLink("conversationDetails", {
        conversationId: conversation.id,
      });
    }

    if (relation instanceof ProjectInviteModelRelation) {
      return buildLink("dashboard");
    }

    if (relation instanceof CustomerInviteModelRelation) {
      return buildLink("dashboard");
    }

    if (relation instanceof OrderModelRelation) {
      return buildLink("support");
    }

    if (relation instanceof InvoiceModelRelation) {
      const invoice = relation.useOptionalInvoice();

      if (!invoice) {
        return;
      }

      return buildLink("invoiceDetails", {
        invoiceId: invoice.id,
        customerId: invoice.data.customerId,
      });
    }

    if (relation instanceof BackupModelRelation) {
      const backup = relation.useOptionalBackup();

      if (!backup) {
        return undefined;
      }

      return buildLink("backup", {
        backupId: backup.id,
        projectId: backup.data.projectId,
      });
    }

    if (relation instanceof RegistryModelRelation) {
      const registry = Registry.useTryLoadById(relation.id);

      if (!registry) {
        return;
      }

      return buildLink("registryDetails", {
        projectId: registry.data.projectId,
        registryId: registry.id,
      });
    }
    return undefined;
  }

  public useTitle(): ReactElement {
    const relation = this.modelRelation;
    const targetText = this.useTargetText();

    return (
      <Translate
        i18n={[
          targetText ? targetText : undefined,
          {
            id: "itemNotFound",
            values: { item: `${relation.type.aggregate}@${relation.id}` },
          },
        ]}
      />
    );
  }
}
