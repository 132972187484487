import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import Contributor from "./Contributor";

export type ExtensionApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Marketplace_Extension;

export type MarketplaceContext =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Marketplace_Context;

export type MarketplaceExternalComponent =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Marketplace_ExternalComponent;

class Extension {
  public readonly data: ExtensionApiData;
  public readonly id: string;
  public readonly isDisabled: boolean;
  public readonly isBlocked: boolean;

  public constructor(data: ExtensionApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.isDisabled = data.state === "disabled";
    this.isBlocked = data.state === "blocked";
  }

  public static useLoadById(extensionId: string): Extension {
    const data = mittwaldApi.extensionGetExtension
      .getResource({ path: { extensionId } })
      .useWatchData();

    return new Extension(data);
  }

  public useContributor(): Contributor {
    return Contributor.useLoadById(this.data.contributorId);
  }
}

export default Extension;
