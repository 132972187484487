import { Box } from "@mittwald/flow-components/dist/components/Box";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import Switch from "@mittwald/flow-components/dist/components/Switch/Switch";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import React, { FC } from "react";

export const ConfigurationDedicationSection: FC = () => {
  return (
    <Section.Item headline="dedication">
      <Box>
        <Switch name="dedicated" textRight />
      </Box>
      <Text i18n="dedicationDescription" small squished />
    </Section.Item>
  );
};

export default ConfigurationDedicationSection;
