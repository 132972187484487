import { filterFactory } from "@mittwald/flow-components/dist/components/ResourceList/hooks/useResourceList/filterFactory";
import CustomerMembership from "../../../../../../../model/customer/CustomerMembership";

export const filter = filterFactory<CustomerMembership>()
  .boolFilter(
    "expiration",
    (customerMembership) => !!customerMembership.data.expiresAt,
  )
  .propFilter("role", (customerMembership, allCustomerRoles) => {
    return allCustomerRoles.includes(customerMembership.role.name);
  })
  .get();
